<!-- cardEvento.vue modificado -->
<template>  
  <div class="card-evento-container">
    <card type="blog" class="card-evento" card-body-classes="text-center">
      <img
        v-if="Eventos.imagen == null"
        slot="image"
        class="container-sm"
        src="imagenes/Capacitacion.webp"
      />
      <img v-else slot="image" class="container-sm" :src="Eventos.imagen" />
      <h6 class="category text-danger">
        <i class="now-ui-icons media-2_sound-wave"></i> {{ Eventos.lugar }}
      </h6>
      <h5 style="font-size: 14px;" class="card-title">
        {{ Eventos.titulo }}
      </h5>
      <h5 style="font-size: 14px;" class="card-title">
        {{ formatearFechaHora(Eventos.fechaHoraInicio) }}
      </h5>
      <div class="card-footer">
        <n-button type="primary" @click.native="abrirModal">Registrarse</n-button>
      </div>
    </card>
  </div>
</template>

<script>
import { Card, Button } from "@/components";

export default {
  props: ["Evento", "modals", "Id"],
  data() {
    return {
      Eventos: {}
    };
  },
  components: {
    Card,
    [Button.name]: Button
  },
  methods: {
    abrirModal() {
      // Emitir evento al componente padre con la información del evento seleccionado
      this.$emit('abrir-modal', this.Eventos);
    },
    formatearFechaHora(fechaStr) {
      if (!fechaStr) return '';
      
      try {
        const fecha = new Date(fechaStr);
        
        // Verificar si la fecha es válida
        if (isNaN(fecha.getTime())) {
          return fechaStr; // Devolver el string original si no es una fecha válida
        }
        
        // Obtener día, mes y año
        const dia = fecha.getDate().toString().padStart(2, '0');
        const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // +1 porque los meses van de 0 a 11
        const año = fecha.getFullYear().toString().slice(-2); // Últimos 2 dígitos del año
        
        // Obtener hora y minutos
        const horas = fecha.getHours().toString().padStart(2, '0');
        const minutos = fecha.getMinutes().toString().padStart(2, '0');
        
        // Formatear como DD/MM/YY a las HH:MM
        return `${dia}/${mes}/${año} a las ${horas}:${minutos}`;
      } catch (error) {
        console.error('Error al formatear fecha:', error);
        return fechaStr; // Devolver el string original en caso de error
      }
    }
  },
  created() {
    this.Eventos = this.Evento;
    console.log(this.Eventos);
  }
};
</script>

<style scoped>
.card-evento-container {
    height: 100%;
    width: 100%;
    padding: 5px;
}

.card-evento {
    height: 100%;
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px; /* Espacio entre tarjetas */
}
.card-title {
    font-size: 14px; /* Tamaño del título */
    text-align: center;
}

.n-button {
    width: 120px; 
    height: 45px;
    font-size: 16px;
}
.container-sm {
    width: 100%;
    height: 100%; /* Cambiado para que ocupe todo el alto disponible */
    object-fit: cover; /* Cambiado para que cubra mejor el espacio */
}

.card {
    height: 100%; /* Para que la tarjeta ocupe todo el alto disponible */
    display: flex;
    flex-direction: column;
}

/* Hacer que el contenido también ocupe todo el espacio disponible */
.card-body {
    flex: 1;
    display: flex;
    flex-direction: column;
}

/* Media Query para móviles */
@media (max-width: 768px) { 
    .wrapper {
        flex-direction: column; /* Apilar las tarjetas en una columna */
        align-items: center; /* Centrar las tarjetas */
        gap: 20px; /* Reducir el espacio entre tarjetas */
    }

    .container-sm {
        max-height: 180px; /* Ajusta la altura de las imágenes */
    }

    .card-title {
        font-size: 13px; 
    }

    .n-button {
        width: 80%; /* Hacer que el botón sea de ancho completo */
        font-size: 10px; 
    }
}
</style>
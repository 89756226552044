<!-- Eventos.vue modificado con título visible -->
<template>
  <div class="blogs-5" data-background-color="grey">
    <div class="container">
      <!-- Título de Eventos destacado y separado del carrusel -->
      <div class="row mb-4">
        <div class="col-md-12 text-center">  
          <h2 class="title-eventos">Eventos</h2>                   
        </div>
      </div>
      
      <!-- Carrusel de eventos en fila separada -->
      <div class="row">        
        <div class="col-md-12 ml-auto mr-auto">
          <div class="row">
            <vueper-slides
              class="tamanio"
              :visible-slides="3"
              :arrows="true"
              :slide-ratio="1 / 1"
              :gap="1"
              :dragging-distance="70"
              :fixed-height="true"
              :bullets="false"
              :breakpoints="breakpoints"
            >
              <vueper-slide v-for="i in slides" :key="i.eventosId" class="slide-evento">
                <template v-slot:content>
                  <keep-alive>
                    <cardEvento
                      :Evento="i"
                      @abrir-modal="abrirModalEvento"
                    >                    
                    </cardEvento>
                  </keep-alive>
                </template>
              </vueper-slide>
            </vueper-slides>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Modal de Registro -->
    <modal :show.sync="showModal">
      <div>
        <form action="">
          <h2 style="color: black;">Registro de Eventos</h2>
          <div class="form-group">
            <div style="display: flex;">
              <div style="flex: 1;">
                <label for="nombre" style="display: block;">Nombre</label>
                <input type="text" v-model="formData.nombre" class="input" style="max-width: 200px;">
              </div>
              <div style="flex: 1;">
                <label for="apellido" style="display: block;">Apellido Paterno</label>
                <input type="text" v-model="formData.apellidoPaterno" class="input" style="max-width: 200px;">
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="email">Correo Electrónico</label>
            <div>
              <input type="email" id="email" class="input" v-model="formData.email">
            </div>
          </div>
          <div class="form-group">
            <label for="fecha">Numero Celular</label>
            <div>
              <input type="text" id="fecha" class="input" v-model="formData.celular">
            </div>
          </div>
          <div class="form-group">
            <label for="seleccion">¿Viene de Parte de una Representacion?</label>
            <div>
              <label style="padding-right: 20px; padding-left: 10px;">
                <input type="radio" name="representacion" value="1" v-model="formData.seleccion" style="cursor: pointer; width: 15px; height: 15px;"> Si
              </label>
              <label>
                <input type="radio" name="representacion" value="2" v-model="formData.seleccion" style="cursor: pointer; width: 15px; height: 15px;"> No
              </label>
            </div>
          </div>
          <div v-if="formData.seleccion == 1" class="form-group">
            <label for="descripcion">Representacion</label>
            <div>
              <select style="cursor: pointer;" id="descripcion" class="input" v-model="formData.representacionId">
                <option v-for="e in listaRepresentaciones" :key="e.representacionId" :value="e.representacionId">
                  {{ e.representacionNombre }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="formData.representacionId != 0 && formData.seleccion == 1" class="form-group">
            <label for="descripcion">Representante</label>
            <div>
              <select style="cursor: pointer;" class="input" v-model="formData.usuarioId">
                <option v-for="u in listaUsuarios" :key="u.representacionId" :value="u.representacionId">
                  {{ u.usuarioNomCompleto }}
                </option>
              </select>
            </div>
          </div>
        </form>
      </div>
      <template slot="footer">
        <n-button style="width: 100px; height: 40px; font-size: 15px;" type="danger" @click.native="showModal = false">
          Cerrar
        </n-button>
        <n-button style="width: 100px; height: 40px; font-size: 15px;" type="primary" @click.native="submitForm()">
          Enviar
        </n-button>
      </template>
    </modal>
    
    <!-- Modal de confirmación -->
    <modal :show.sync="showConfirmacionModal">
      <div style="text-align: center;">
        <h3 style="color: black; text-align: center;">Registro Exitoso</h3>
        <img src="../../public/imagenes/aprobado.png" alt="Palomita de éxito" style="width: 120px; height: 120px;">
      </div>
      <div style="text-align: center; margin-top: 3rem;">
        <n-button style="width: 100px; height: 40px; font-size: 15px;" type="danger" @click.native="showConfirmacionModal = false">
          Aceptar
        </n-button>
      </div>
    </modal>
  </div>
</template>

<script>
import { Card, Button, Modal, FormGroupInput } from "@/components";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import cardEvento from "./cardEvento";
import store from "../store";
import servRep from "@/Servicios/Representaciones.js";
import servEvento from "@/Servicios/EventoUsuario.js";
import servUsuarios from "@/Servicios/Usuarios.js";

export default {
  computed: {
    slides() {
      return store.state.slides;
    },
  },
  components: {
    Card,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    VueperSlides,
    VueperSlide,
    cardEvento,
    Modal
  },
  data() {
    return {
      breakpoints: {
        800: {
          visibleSlides: 2,
        },
        600: {
          visibleSlides: 1,
        }
      },
      showModal: false,
      showConfirmacionModal: false,
      eventoSeleccionado: null,
      listaRepresentaciones: [],
      listaUsuarios: [],
      formData: {
        nombre: "",
        apellidoPaterno: "",
        email: "",
        celular: "",
        representacionId: 0,
        seleccion: 0,
        usuarioId: 0
      }
    };
  },
  methods: {
    abrirModalEvento(evento) {
      this.eventoSeleccionado = evento;
      this.showModal = true;
    },

    obtenerRepresentaciones() {
      servRep.listado()
        .then((r) => {
          this.listaRepresentaciones = r.data;
        });
    },

    obtenerUsuarios() {
      if (this.formData.representacionId) {
        servUsuarios.getid(this.formData.representacionId)
          .then((r) => {
            this.listaUsuarios = r.data;
          });
      }
    },

    submitForm() {
      const idRep = 10;
      if (this.formData.seleccion == 2) {
        this.formData.usuarioId = idRep;
      }

      let data = {
        Id: 0,
        Nombre: this.formData.nombre,
        Correo: this.formData.email,
        celular: this.formData.celular,
        ApellidoPaterno: this.formData.apellidoPaterno,
        RepresentanteId: this.formData.usuarioId,
        EventosId: this.eventoSeleccionado.eventosId,
        EstadoId: 1,
      };

      servEvento.post(data)
        .then((r) => {
          console.log(r);
          this.showModal = false;
          this.showConfirmacionModal = true;
          // Limpiar formulario
          this.resetForm();
        });
    },

    resetForm() {
      this.formData = {
        nombre: "",
        apellidoPaterno: "",
        email: "",
        celular: "",
        representacionId: 0,
        seleccion: 0,
        usuarioId: 0
      };
    }
  },
  created() {
    this.obtenerRepresentaciones();
  },
  watch: {
    'formData.representacionId': function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.obtenerUsuarios();
      }
    }
  }
};
</script>

<style scoped>
.tamanio {
  height: 480px;
  width: 100%;
  margin-bottom: 20px;
}

.Input-color {
  color: black;
}

/* Estilos específicos para el título de Eventos */
.title-eventos {
  color: #87211f;
  font-size: 2.5rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: 20px 0;
  position: relative;
  display: inline-block;
  padding: 0 15px;
  letter-spacing: 1px;
  z-index: 10;
}

.title-eventos::after {
  content: '';
  display: block;
  width: 100px;
  height: 4px;
  background-color: #87211f;
  margin: 10px auto 0;
}

.form-group {
  margin-bottom: 15px;
}

.label {
  font-weight: bold;
}

.input {
  padding: 3px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 100%; 
}

.slide-evento {
  height: 100%;
  padding: 0;
}

/* Media Query para móviles */
@media (max-width: 768px) { 
  .tamanio {
    height: 390px;
  }
  
  .n-button {
    width: 80%;
    font-size: 10px; 
  }
  
  .title-eventos {
    font-size: 2rem;
    margin: 15px 0;
  }
  
  .title-eventos::after {
    width: 80px;
  }
}

@media (max-width: 576px) {
  .tamanio {
    height: 350px;
  }
  
  .title-eventos {
    font-size: 1.8rem;
  }
}
</style>